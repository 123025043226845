import regularFetcher from '../regularFetcher';

const userHint = function (cpf_cnpj: string) {
  return regularFetcher({
    endpoint: `/v4/users/email-hint?cpf_cnpj=${cpf_cnpj}`,
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
  });
};

export default userHint;
