import type { AppProps } from 'next/app';
import { useMe } from '@2ndmarket/services';
import { GoogleTagManager, themes } from '@2ndmarket/components';
import CssBaseline from '@mui/material/CssBaseline';

function MyApp({ Component, pageProps }: AppProps) {
  const { ThemeProvider, authentication } = themes;

  return (
    <ThemeProvider theme={authentication}>
      <GoogleTagManager
        userOrigin={useMe}
        theme={authentication}
      />
      <CssBaseline />
      <Component {...pageProps} />
    </ThemeProvider>
  );
}

export default MyApp;
