import regularFetcher from '../regularFetcher';

const userLogin = function (
  username: string,
  password: string,
  scrope: string = 'user') {
  // @ts-ignore
  const formData = new FormData();
  formData.append('client_id', username);
  formData.append('client_secret', password);
  formData.append('scope', scrope);

  return regularFetcher({
    endpoint: '/v4/auth',
    method: 'POST',
    body: formData,
  });
};

export default userLogin;
