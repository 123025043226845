import regularFetcher from '../authenticatedFetcher';

const userLogout = () => {
  return regularFetcher({
    endpoint: `/v4/logout`,
    headers: {
      Authorization:
        'Bearer ' + localStorage.getItem('@2ndMarket-token'),
    },
    method: 'DELETE',
  });
};

export default userLogout;
