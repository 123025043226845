import regularFetcher from '../regularFetcher';

const userRegister = function (
  name: string,
  email: string,
  cpf_cnpj: string,
  person_type: string,
) {
  return regularFetcher({
    endpoint: '/v4/users/register',
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({
      email: email,
      name: name,
      cpf_cnpj: cpf_cnpj,
      person_type: person_type,
    }),
  });
};

export default userRegister;
