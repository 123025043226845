export const validateName = function(name:string) {

    if (typeof name !== 'string' || !name) {

        return false
    }

    const trimmedName = name.trim()

    if(trimmedName.length < 3 || trimmedName.length > 100) {

        return false
    }

    return trimmedName.split(" ").length > 1;
}